<template>
  <div>
    <a-card>
      <div class="row">
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
          <!-- <div>
            <p>Speed: x{{ animationSpeed }}</p>
            <input
              type="range"
              value="1"
              min="0"
              max="3"
              step="0.5"
              @change="onSpeedChange"
              v-model="animationSpeed"
            />
          </div>
          <button @click="stop">stop</button>
          <button @click="pause">pause</button>
          <button @click="play">play</button> -->
        </div>
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
          <a-button class="btn btn-success ml-2 mt-2 mb-2" @click="AskForce"
            >Initial All Form</a-button
          >
          <br />
          <div class="ml-3">
            Form ini akan secara otomatis memproses inisialisasi form-form
            berikut:<br />
            <!-- Initial:<br /> -->
            <!-- - Form 01<br />
            - Form 01.1<br />
            - Form 02<br />
            - Form 09<br />
            - Form 10<br />
            - Form 14<br />
            <br />
            Ambil periode sebelumnya:<br />
            - Form 00<br />
            - Form 00.1<br />
            - Form 00.2<br />
            - Form 00.3<br />
            - Form 00.4<br />
            - Form 00.5<br />
            - Form 00.6<br /> -->
          </div>
          <a-table
            :key="componentKeyI"
            class="mt-2"
            :columns="columnViewTalbeI"
            :dataSource="dataViewTableI"
            :pagination="false"
            size="small"
          >
          <template slot="formname" slot-scope="text">
            {{ text }}
            <!-- <mylottiecomp
            :options="defaultOptions"
            v-on:animCreated="handleAnimation"
            v-if="record.statuscondition === 'text-primary'"
          ></mylottiecomp> -->
          </template>
            <span
              slot="status"
              slot-scope="text, record"
              :class="record.statuscondition"
            >
              {{ text }}
              <span v-if="text !== '' && text !== null">
                <a-icon
                  type="close"
                  :class="record.statuscondition"
                  v-if="record.statuscondition === 'text-danger'"
                />
                <!-- <mylottiecomp
            :options="defaultOptions"
            v-on:animCreated="handleAnimation"
            v-else
          ></mylottiecomp> -->
                <!-- <a-icon type="check" :class="record.statuscondition" v-else /> -->
              </span>
            </span>
          </a-table>
          <a-table
            :key="componentKeyC"
            class="mt-2"
            :columns="columnViewTalbeC"
            :dataSource="dataViewTableC"
            :pagination="false"
            size="small"
          >
          <template slot="formname" slot-scope="text">
            {{ text }}
            <!-- <mylottiecomp
            :options="defaultOptions"
            v-if="record.statuscondition === 'text-primary'"
            v-on:animCreated="handleAnimation"
          ></mylottiecomp> -->
          </template>
            <span
              slot="status"
              slot-scope="text, record"
              :class="record.statuscondition"
            >
              {{ text }}
              <span v-if="text !== '' && text !== null">
                <a-icon
                  type="close"
                  :class="record.statuscondition"
                  v-if="record.statuscondition === 'text-danger'"
                />
                <!-- <mylottiecomp
            :options="defaultOptions"
            v-on:animCreated="handleAnimation"
            v-else
          ></mylottiecomp> -->
                <!-- <a-icon type="check" :class="record.statuscondition" v-else /> -->
              </span>
            </span>
          </a-table>
        </div>
      </div>
    </a-card>
  </div>
</template>

<script>
import * as lou from '@/services/data/lou'
import animationData from '@/assets/animations/33886-check-okey-done.json'
import moment from 'moment'
// import mylottiecomp from '@/components/lou/lottie'
// import { Modal } from 'ant-design-vue'

export default {
  components: {
    // mylottiecomp,
  },
  data() {
    return {
      defaultOptions: { animationData: animationData },
      animationSpeed: 1,
      columnViewTalbeI: [
        {
          title: 'Initial Forms',
          dataIndex: 'formname',
          width: '30%',
          scopedSlots: { customRender: 'formname' },
        },
        {
          title: 'Status',
          dataIndex: 'status',
          width: '30%',
          scopedSlots: { customRender: 'status' },
        },
      ],
      columnViewTalbeC: [
        {
          title: 'Forms Ambil Periode Sebelumnya',
          dataIndex: 'formname',
          width: '30%',
          scopedSlots: { customRender: 'formname' },
        },
        {
          title: 'Status',
          dataIndex: 'status',
          width: '30%',
          scopedSlots: { customRender: 'status' },
        },
      ],
      dataViewTableI: [
        {
          formname: 'Form 00.14',
          actualform: 'form0014s',
          statuscondition: '',
          status: '',
          response: null,
        },
        {
          formname: 'Form 00.15',
          actualform: 'form0015s',
          statuscondition: '',
          status: '',
          response: null,
        },
        {
          formname: 'Form 01',
          actualform: 'form01s',
          statuscondition: '',
          status: '',
          response: null,
        },
        {
          formname: 'Form 01.1',
          actualform: 'form011s',
          statuscondition: '',
          status: '',
          response: null,
        },
        {
          formname: 'Form 02',
          actualform: 'form02s',
          statuscondition: '',
          status: '',
          response: null,
        },
        {
          formname: 'Form 09',
          actualform: 'form09s',
          statuscondition: '',
          status: '',
          response: null,
        },
        {
          formname: 'Form 10',
          actualform: 'form10s',
          statuscondition: '',
          status: '',
          response: null,
        },
        {
          formname: 'Form 14',
          actualform: 'form14s',
          statuscondition: '',
          status: '',
          response: null,
        },
      ],
      dataViewTableC: [
        {
          formname: 'Form 00',
          actualform: 'form00s',
          statuscondition: '',
          status: '',
          response: null,
        },
        {
          formname: 'Form 00.1',
          actualform: 'form001s',
          statuscondition: '',
          status: '',
          response: null,
        },
        {
          formname: 'Form 00.2',
          actualform: 'form002s',
          statuscondition: '',
          status: '',
          response: null,
        },
        {
          formname: 'Form 00.3',
          actualform: 'form003s',
          statuscondition: '',
          status: '',
          response: null,
        },
        {
          formname: 'Form 00.4',
          actualform: 'form004s',
          statuscondition: '',
          status: '',
          response: null,
        },
        {
          formname: 'Form 00.5',
          actualform: 'form005s',
          statuscondition: '',
          status: '',
          response: null,
        },
        {
          formname: 'Form 00.6',
          actualform: 'form006s',
          statuscondition: '',
          status: '',
          response: null,
        },
      ],
      resultResponse: [],
      form: ['form0014s', 'form0015s', 'form01s', 'form011s', 'form02s', 'form09s', 'form10s', 'form14s'],
      formPrevious: [
        'form00s',
        'form001s',
        'form002s',
        'form003s',
        'form004s',
        'form005s',
        'form006s',
      ],
      componentKeyI: 0,
      componentKeyC: 1,
    }
  },
  methods: {
    moment,
    handleAnimation(anim) {
      this.anim = anim
    },

    stop() {
      this.anim.stop()
    },

    play() {
      this.anim.play()
    },

    pause() {
      this.anim.pause()
    },

    onSpeedChange() {
      this.anim.setSpeed(this.animationSpeed)
    },
    AskForce() {
      // Modal.confirm({
      //   title: 'Timpa?',
      //   content: 'Apakah anda ingin menimpa data saat ini!',
      //   okText: 'Yes',
      //   okType: 'success',
      //   cancelText: 'No',
      //   onOk: () => {
      this.InitAllForm(true)
      //   },
      //   onCancel: () => {
      //     this.InitAllForm(false)
      //   },
      // })
    },
    async InitAllForm(force) {
      var rescopypreviousform4 = await lou.customUrlGet(
        'form004s/copyprevious?force=' + force,
        false,
        false,
      )
      if (rescopypreviousform4) {
      }
      var res = []
      this.resultResponse = []
      for (let i = 0; i < this.form.length; i++) {
        const element = this.form[i]
        var tres = await lou.customUrlGet(
          element + '/initial?force=' + force,
          false,
          false,
          true,
        )
        this.resultResponse.push({
          form: element,
          res: tres,
        })
        var targetI = this.dataViewTableI.findIndex(
          (x) => element === x.actualform,
        )
        if (targetI >= 0) {
          if (tres.isError) {
            this.dataViewTableI[targetI].statuscondition = 'text-danger'
            this.dataViewTableI[targetI].status = tres.message
          } else {
            this.dataViewTableI[targetI].statuscondition = 'text-primary'
            this.dataViewTableI[targetI].status = tres.message
          }
        } else {
          this.dataViewTableC[i].statuscondition = 'text-danger'
          this.dataViewTableC[i].status = 'Tidak ada response dari server.'
        }
        if (tres) {
          res.push({
            form: element,
            res: tres,
          })
        }
        if (i === this.form.length - 1) {
          if (res.length === this.form.length) {
            lou.shownotif(
              'Success',
              'All form has been successfully inititalized.',
              'success',
            )
          } else {
            lou.shownotif(
              'Info',
              "There's form that failed to be initialized.",
              'info',
            )
          }
          this.CopyPreviousAllForm(force)
        }
      }
    },
    async CopyPreviousAllForm(force) {
      var res = []
      for (let i = 0; i < this.formPrevious.length; i++) {
        const element = this.formPrevious[i]
        var tres = await lou.customUrlGet(
          element + '/copyprevious?force=' + force,
          false,
          false,
          true,
        )
        this.resultResponse.push({
          form: element,
          res: tres,
        })
        var targetC = this.dataViewTableC.findIndex(
          (x) => element === x.actualform,
        )
        if (targetC >= 0) {
          if (tres.isError) {
            this.dataViewTableC[targetC].statuscondition = 'text-danger'
            this.dataViewTableC[targetC].status = tres.message
          } else {
            this.dataViewTableC[targetC].statuscondition = 'text-primary'
            this.dataViewTableC[targetC].status = tres.message
          }
        } else {
          this.dataViewTableC[i].statuscondition = 'text-danger'
          this.dataViewTableC[i].status = 'Tidak ada response dari server.'
        }
        if (tres) {
          res.push({
            form: element,
            res: tres,
          })
        }
        if (i === this.formPrevious.length - 1) {
          if (res.length === this.formPrevious.length) {
            lou.shownotif(
              'Success',
              'All form has been successfully executed.',
              'success',
            )
          } else {
            lou.shownotif(
              'Info',
              "There's form that failed to be executed.",
              'info',
            )
          }
        }
      }
    },
  },
}
</script>

<style>
canvas {
  width: 25px !important;
  height: 25px !important;
}
</style>
